<template>
  <df-select-dropdown
    ref="dropdown"
    :items="items"
    :clearable="clearable"
    :multiple="multiple"
    :search-bar="searchBar"
    :search-placeholder="searchPlaceholder"
    :value="value"
    @apply-filter="applyMultipleFilter"
    @clear="clear"
    @click="applySingleFilter"
  >
    <template #default="slotProps">
      <df-button
        class="select-button"
        icon="chevron-down"
        outlined
        variant="text-secondary"
        v-on="slotProps.on"
        :class="showGreenBorder"
        :color-icon="greenIconColor"
        :disabled="disabled"
        :loading="loading"
        :ripple="false"
        :width="width"
      >
        <span v-if="label" class="filter__label"> {{ label }}: </span>
        <span v-if="!multiple" ref="filterValue" class="filter__value">
          {{ componentValue.name }}
        </span>
        <div
          v-if="showPlaceholder"
          class="df-flex-sm align-center filter__placeholder"
        >
          <span>
            {{ placeholder }}
          </span>
          <div v-if="showQuantityTag" class="placeholder__quantity">
            {{ value.length }}
          </div>
        </div>
      </df-button>
    </template>
  </df-select-dropdown>
</template>

<script>
import DfButton from '@/lib/components/Button/DfButton.vue'
import DfSelectDropdown from '@/lib/components/Dropdown/DfSelectDropdown.vue'

export default {
  name: 'DfFilterSelect',

  components: {
    DfButton,
    DfSelectDropdown,
  },

  props: {
    clearable: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    items: {
      required: true,
      type: Array,
      validator(value) {
        return value.every((item) => {
          return (
            typeof item.name === 'string' &&
            (typeof item.value === 'string' || item.value === null)
          )
        })
      },
    },
    label: {
      default: '',
      type: String,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    multiple: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      default: '',
      type: String,
    },
    searchBar: {
      default: false,
      type: Boolean,
    },
    searchPlaceholder: {
      default: '',
      type: String,
    },
    value: {
      required: true,
      type: [Array, Object],
    },
    width: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      componentValue: this.multiple ? [] : this.value,
    }
  },

  mounted() {
    const horizontalPadding = 24
    const iconWidth = 21
    if (this.$refs.filterValue)
      this.$refs.filterValue.style.maxWidth =
        this.width - horizontalPadding - iconWidth + 'px'
  },

  watch: {
    value(value) {
      if (!value || !Object.keys(value).length) {
        this.componentValue = this.multiple ? [] : value
      }
    },
  },

  computed: {
    greenIconColor() {
      return '#39AF49'
    },
    showPlaceholder() {
      return this.placeholder && !this.componentValue.name
    },
    showGreenBorder() {
      const hasGreenBorderRule = Object.keys(this.componentValue).includes(
        'isDefault'
      )
      if (hasGreenBorderRule)
        return this.componentValue.isDefault ? '' : 'button__color'
      else return ''
    },
    showQuantityTag() {
      return this.multiple && this.value.length
    },
  },

  methods: {
    applyMultipleFilter(value) {
      this.componentValue = value
      this.$emit('input', [...this.componentValue])
      this.$emit('apply-filter')
    },
    applySingleFilter(value) {
      this.componentValue = value
      this.$emit('input', value)
      this.$emit('click', value)
    },
    clear(value) {
      if (value || (value && Object.keys(value).length))
        this.componentValue = value
      else this.componentValue = []
      this.$refs.dropdown.menu = false
      this.$emit('clear', this.componentValue)
    },
    // accessed by refs
    // eslint-disable-next-line vue/no-unused-properties
    cleanFilter(value) {
      this.clear(value)
      this.$refs.dropdown.cleanFilter(value)
    },
    // accessed by refs
    // eslint-disable-next-line vue/no-unused-properties
    setSingleValue(value) {
      this.componentValue = value
      this.$refs.dropdown.componentValue = value
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.select-button {
  &.v-btn--disabled {
    background-color: #e6e9e6 !important;

    & .filter__placeholder span {
      color: #aab2a9;
    }
  }
  &:hover {
    background-color: #d8f2dc !important;
    box-shadow: none !important;
  }
}
.button__color {
  border-color: #39af49;
}
.filter__label {
  color: #5b6459;
  font-family: 'Rubik';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  white-space: nowrap;
}
.filter__value {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 14px;
}
.filter__placeholder {
  span {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
  .placeholder__quantity {
    height: 16px;
    width: 16px;
    border-radius: 9999px;
    color: #fff;
    background-color: #39af49;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    padding: 1px 1px 0px 0px;
  }
}
.df-button {
  height: 40px !important;
}
.v-btn::before {
  background-color: transparent !important;
}
.v-btn--disabled .filter__label {
  color: #00000042;
}
::v-deep .text-personalized {
  display: flex;
  align-items: center;
  gap: 4px;
}
</style>
